import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";

export class AddCouponList extends Component {
  coupon_list = [
    { value: 0, label: "Percentage(%)" },
    { value: 1, label: "Cash" },
  ];



  constructor(props) {
    super(props);

    this.state = {
      'title': "",
      'code': "",
      'coupon_type': "",
      'value': "",
      'max_value': "",
      'count': "",
      'description': "",
    };
  }


  addCouponsList = async () => {
    var data = {
      'title': this.state.title,
      'code': this.state.code,
      'coupon_type': this.state.coupon_type,
      'value': this.state.value,
      'max_value': this.state.max_value,
      'count': this.state.count,
      'description': this.state.description,
    };

    var response = await Api.PostRequest(data, "coupons");
    if (response.status === 200) {
      toast.success(response.message);
      this.setState({
        'title': "",
        'code': "",
        'coupon_type': "",
        'value': "",
        'max_value': "",
        'count': "",
        'description': "",
      });
      
    } else {
      toast.error(response.message);
    }
  };

  render() {
    return (
      <div>
        <div className="pcoded-main-container" style={{ textAlign: "left" }}>
          <div className="pcoded-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">Create Coupons</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">
                          <i className="feather icon-home"></i>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#!">Coupons</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#!">Add Coupon</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="reservation-detais form-inner">
                      <div className="row form-wrap">
                        <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                          <label> Coupan </label>
                          <input  type="text" name="coupan" id="coupan" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.title} onChange={(event) => {this.setState({ title: event.target.value })}}/> 
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                          <label> Coupan Code</label>
                          <input  type="text"  name="coupan_code"  id="coupan_code" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}  value={this.state.code} onChange={(event) => {  this.setState({ code: event.target.value })}} /> 
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                          <label> Select Coupan</label>

                          <Select options={this.coupon_list} onChange={(event) => { this.setState({ coupon_type: event.value }) }} /> 
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                          <label> Flat Discount</label>
                          <input type="text" name="flat_discount" id="flat_discount"  placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}value={this.state.value}onChange={(event) => {this.setState({ value: event.target.value })}}/>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                          <label> Coupan Max Value</label>
                          <input type="text"  name="coupan_max_value" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}value={this.state.max_value} onChange={(event) => {this.setState({ max_value: event.target.value })}}/>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                          <label> Coupan Count</label>
                          <input type="text" name="coupan_count" id="coupan_count"  placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.count}onChange={(event) => { this.setState({ count: event.target.value })}}/>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                          <label>Coupan Discription</label>
                          <textarea type="text" name="coupan_discription" id="coupan_discription"    placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.description}  onChange={(event) => { this.setState({ description: event.target.value })}} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                      <button type="button" onClick={() => { this.addCouponsList()}} className="btn btn-success" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>Create Coupan</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddCouponList;
                        
                        
                          
                        
                        
                        
                      
                        
                      
                    
                            
                            
                            
                         
                            
                            
                           
                             
                            
                         
                       
                            
                            
                            
                           
                            
                            
                            
                             
                            
                          
                       
                            
                           
                            
                            
                            
                            
                           
                              
                            
                          
                        
                            
                            
                            
                           
                            
                            
                            
                              
                            
                          
                        
                            
                            
                              
                           
                          
                        
                           
                           
                           
                            
                            
                           
                            
                             
                            
                         
                        
                           
                            
                            
                            
                            
                            
                           
                              
                           
                          
                       

                        
                       

                        
                       
                        
                        
                      
                    

                    
                  
                
              
            
          
        
      
    
  



