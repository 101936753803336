import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";

export class AddCancelReasons extends Component {
  reason_list = [
    { value: 2, label: "Customer" },
    { value: 3, label: "Driver" },
  ];

  constructor(props) {
    super(props);

    this.state = {
      'title': "",
      'reason_for': ""
    };
  }


  addCancelReasons = async () => {
    var data = {
      'title': this.state.title,
      'reason_for':this.state.reason_for
    };


    var response = await Api.PostRequest(data, "cancel-reasons");
    if (response.status === 200) {
      toast.success(response.message);
      this.setState({
        'title' : "",
        'reason_for': ""
      });
      
    } else {
      toast.error(response.message);
    }
  };
  render() {
    return (
      <div>
        <div>
          <div class="pcoded-main-container" style={{ textAlign: "left" }}>
            <div class="pcoded-content">
              <div class="page-header">
                <div class="page-block">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="page-header-title">
                        <h5 class="m-b-10">Add cancel Reasons</h5>
                      </div>
                      <ul class="breadcrumb">
                        <li class="breadcrumb-item">
                          <a href="index.html">
                            <i class="feather icon-home"></i>
                          </a>
                        </li>
                        <li class="breadcrumb-item">
                          <a href="#!">Cancel Reasons</a>
                        </li>
                        <li class="breadcrumb-item">
                          <a href="#!"> Add cancel Reasons</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-body">
                      
                        <div class="reservation-detais form-inner">
                          <div class="row form-wrap">
                            <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                              <input type="text" placeholder="Cancel Reason Title" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.title} onChange={(event) => {this.setState({ title: event.target.value })}} />
                            </div>

                            <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                              <label> Reason for</label>
                              <Select options={this.reason_list} onChange={(event) => { this.setState({ reason_for: event.value }) }} />
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                          <button type="submit" onClick={() => { this.addCancelReasons()}} class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                            Create cancel Reason
                          </button>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="pcoded-main-container" style={{ textAlign: "left" }}>
            <div class="pcoded-content">
              <div class="page-header">
                <div class="page-block">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="page-header-title">
                        <h5 class="m-b-10">Add cancel Reasons</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-block">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                    <h5 class="m-b-10" style={{ marginLeft: "20px" }}>
                      {" "}
                      Dashboard
                    </h5>
                    <li class="m-b-10" style={{ marginLeft: "20px" }}>
                      <a href="#!">Cancel Reasons</a>
                    </li>
                  </div>

                  <div class="col-md-12">
                    <div class="card" style={{ borderRadius: "15px" }}>
                      <div class="card-body">
                        <form method="post" class="reservation-form">
                          <div class="reservation-detais form-inner">
                            <div class="row form-wrap">
                              <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                                <input type="text" placeholder="Cancel Reason Title" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                              </div>

                              <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                                <select style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}>
                                  <option value="">Select Reason For</option>
                                  <option value="option1">option1</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                            <button type="submit" class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                              Create cancel Reason
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default AddCancelReasons;
