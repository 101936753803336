import React, { Component } from "react";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

export class DriverPartnerList extends Component {

  
  componentDidMount = async () => {
    this.getDriverPartners();
  };



  getDriverPartners = async () => {
    var response = await Api.GetRequest({}, "driver-partners");
    if (response.status == 200) {
      console.log(response);
      this.setState({ drivers: response.driver_partners });
    } else {
    }
  };



  constructor(props) {
    super(props);
    this.state = {
      drivers: [],
    };
  }

  render() {

    const columns =[
			{
				name :"S.NO.",
				selector: (row, index) => index +1,
				sortable :true

			},
			{
				name :"Driver Id",
				selector: row => row.id,
				sortable :true

			},
			{
				name :"Name",
				selector: row => row.name,
				sortable :true

			},
			{
				name :"Mobile",
				selector: row => row.mobile,
				sortable :true

			},
			{
				name :"Email",
				selector: row => row.email,
				sortable :true

			},
			{
				name :"Exeprience",
				selector: row => row.experience,
				sortable :true

			},

      
			{
				name :"Trips Count",
				selector: row => row.trip_count,
				sortable :true

			},
			{
				name :"Rating",
				selector: row => row.rating,
				sortable :true

			},
			{
				name :"Status",
				selector: row => (
          row.status === 0?
          <span className="badge badge-danger">Inactive</span>:
          <span className="badge badge-success">Active</span>

        ),
				sortable :true

			},
      {
        name : "Action",
        cell : row =>(

          <div className="text-right" style ={{display:"flex"

          }}>
            <Link to={`/driver-partner/edit/${row.id}`} className="btn btn-info btn-sm mx-2" style={{whiteSpace:"nowrap"}}>
               <i className="feather icon-edit"></i>&nbsp;Edit{" "}
            </Link>
            <button  className="btn btn-danger btn-sm mx-2" style={{whiteSpace:"nowrap"}}>
              <i className="feather icon-trash-2"></i>&nbsp;Delete{" "}
            </button>
            

          </div>
        )
      }
			
		]
    return (
      <div>
        <div className="pcoded-main-container" style={{ textAlign: "left" }}>
          <div className="pcoded-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">Driver List</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">
                          <i className="feather icon-home"></i>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#!">Dashboard Analytics</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                  <h5 className="m-b-10" style={{ marginLeft: "20px" }}>
                    Dashboard
                  </h5>
                  <li className="m-b-10" style={{ marginLeft: "20px" }}>
                    <a href="#!">Driver List</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: "10px", marginRight: "20px" }}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                      <DataTable 
                          columns ={columns}
                          data ={this.state.drivers}
                          pagination
                          selectableRows
                          expandableRows
                          expandableRowsComponent ={({data}) =>(
                          <div>
                            <p><strong>Title :</strong>{data.id}</p>
                            <p><strong>Name :</strong>{data.name}</p>
                            <p><strong>Email :</strong>{data.email}</p>
                            <p><strong>Mobile :</strong>{data.mobile}</p>
                            <p><strong>Exeprience :</strong>{data.experience } </p>
                            <p><strong>Trips Counts :</strong>Trip count</p>
                            <p><strong>Rating :</strong>Rating</p>
                            <p><strong>Status :</strong>{data.status == 0 && <span className="badge badge-danger ">In Active</span>}
                            {data.status == 1 && <span className="badge badge-success ">Active</span>}</p>
                            
                           

                          </div>

                          )}
                        
                       />
                    {/* <table id="full-row-reorder" className="table table-striped table-bordered nowrap">
                      <thead>
                        <tr>
                          <th>All</th>
                          <th>Active</th>
                          <th colSpan="7" style={{ border: "none" }}>
                            In Active
                          </th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <td colSpan="7" style={{ border: "none" }}>
                            <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </td>
                        </tr>
                      </thead>

                      <thead>
                        <tr>
                          <th>S. No.</th>
                          <th>Driver id</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Exeprience</th>
                          <th>Trips count</th>
                          <th>Rating</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.drivers.map((driver, index) => {
                          return (
                            <tr>
                              <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                              <td style={{ verticalAlign: "middle" }}>ID</td>
                              <td style={{ verticalAlign: "middle" }}>{driver.name}</td>
                              <td style={{ verticalAlign: "middle" }}>{driver.email}</td>
                              <td style={{ verticalAlign: "middle" }}>{driver.mobile}</td>
                              <td style={{ verticalAlign: "middle" }}>{driver.experience} Years</td>
                              <td style={{ verticalAlign: "middle" }}>Trip count</td>
                              <td style={{ verticalAlign: "middle" }}>Rating</td>
                              <td style={{ verticalAlign: "middle" }}>
                                {driver.status == 0 && <span className="badge badge-danger">In Active</span>}
                                {driver.status == 1 && <span className="badge badge-success">Active</span>}
                              </td>
                              <td className="text-right">
                                <Link to={`/driver-partner/edit/${driver.id}`} className="btn btn-info btn-sm mx-2">
                                  <i className="feather icon-edit"></i>&nbsp;Edit{" "}
                                </Link>
                                <button className="btn btn-danger btn-sm mx-2">
                                  <i className="feather icon-trash-2"></i>&nbsp;Delete{" "}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DriverPartnerList;
