import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";

export class AddAdditionalCharges extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          'title': "",
          'price':""
        };
      }
    
    
      addAdditionalCharge = async () => {
        var data = {
          'title': this.state.title,
          'price':this.state.price
        };

    
        var response = await Api.PostRequest(data, "additional-charges");
        if (response.status === 200) {
          toast.success(response.message);
          this.setState({
            'title': "",
            'price':""
          });
          
        } else {
          toast.error(response.message);
        }
      };
  render() {
    return (
      <div>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10"> Add Additional Charges</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">rates</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!"> Additional Charges</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                
                  <div class="col-md-12">
                    <div class="card" style={{ borderRadius: "15px" }}>
                      <div class="card-body">
                        
                          <div class="reservation-detais form-inner">
                            <div class="row form-wrap">
                              <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                              <label> Additional Charge Title </label>
                                <input type="text" name="add-additional-charge" id="add-additional-charge" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.title} onChange={(event) => {this.setState({ title: event.target.value })}}/>
                              </div>

                              <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                              <label> Price </label>
                                <input type="text" name="value" id="value" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.price} onChange={(event) => {this.setState({ price: event.target.value })}}/>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                            <button type="button" onClick={() => { this.addAdditionalCharge()}} class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                              Create Additional Charge
                            </button>
                          </div>
                        
                      </div>
                    </div>
                  </div>
              
                  
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddAdditionalCharges
