import React from "react";
import Auth from "../Services/auth";
const base_url = process.env.REACT_APP_API_URL;

const PostRequest = async (data, endPoint) => {
  var auth_token = await Auth.GetToken();
  return fetch(base_url + endPoint, {
    method: "POST",
    body: JSON.stringify(data),
    credentials: "include",
    headers: new Headers({
      "Content-type": "application/json",
      "Authorization": "Token " + auth_token,
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.log(error);
      alert("Something went wrong. please try again later");
    });
};



const GetRequest=async (params, endPoint)=>{
  var url = base_url + endPoint
  if(Object.keys(params).length > 0){
    url =  url + '?' + new URLSearchParams(params)
  }
  var auth_token =  await Auth.GetToken();
  return fetch(url, {
    method: 'GET',
    credentials : 'include',
    headers : new Headers({
        'Content-Type':'application/json',
        "Authorization": "Token " + auth_token
      }),
  })
  .then((response) => response.json())
  .then((responseJson) => {
    return(responseJson);
  })
  .catch((error)=>{
    // alert("Something went wrong. please try again later.");
  });   
};


export default { PostRequest, GetRequest };
