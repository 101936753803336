import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Faqs extends Component {
  render() {
    return (
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <Link to="/policy_type/AddPolicyType">
            <button  type="submit" class="btn btn-success"  >
              <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add FAQs
            </button>
          </Link>
        </div>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">FAQ List</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Help And Support</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!"> FAQ List</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="dt-responsive table-responsive">
                      <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                        <thead>
                          <tr>
                            <th style={{ border: "none" }}>All</th>
                            <th style={{ border: "none" }}>User</th>

                            <th colSpan="9" style={{ border: "none" }}>
                              Driver
                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <td colSpan="9" style={{ border: "none" }}>
                              <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                            </td>
                          </tr>
                        </thead>

                        <thead>
                          <tr>
                            <th style={{ border: "none" }}>ID</th>
                            <th style={{ border: "none" }}>Question</th>

                            <th style={{ border: "none" }}>Answer</th>
                            <th style={{ border: "none" }}>Status</th>
                            <th style={{ border: "none" }}>User Type</th>
                            <th style={{ border: "none" }}>Status</th>
                            <th style={{ border: "none" }}>Created at</th>
                            <th style={{ border: "none" }}>Updated at</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">FAQ List </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-block">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                  <h5 class="m-b-10" style={{ marginLeft: "20px" }}>
                    {" "}
                    Dashboard
                  </h5>
                  <li class="m-b-10" style={{ marginLeft: "20px" }}>
                    <a href="#!">FAQ List</a>
                  </li>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                  <Link to="/policy_type/AddPolicyType">
                    <button type="submit" class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                      <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add FAQs
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style={{ marginLeft: "10px", marginRight: "20px" }}>
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="dt-responsive table-responsive">
                    <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                      <thead>
                        <tr>
                          <th style={{ border: "none" }}>All</th>
                          <th style={{ border: "none" }}>User</th>

                          <th colSpan="9" style={{ border: "none" }}>
                            Driver
                          </th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <td colSpan="9" style={{ border: "none" }}>
                            <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </td>
                        </tr>
                      </thead>

                      <thead>
                        <tr>
                          <th style={{ border: "none" }}>ID</th>
                          <th style={{ border: "none" }}>Question</th>

                          <th style={{ border: "none" }}>Answer</th>
                          <th style={{ border: "none" }}>Status</th>
                          <th style={{ border: "none" }}>User Type</th>
                          <th style={{ border: "none" }}>Status</th>
                          <th style={{ border: "none" }}>Created at</th>
                          <th style={{ border: "none" }}>Updated at</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              0
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Faqs;
