import React, { Component } from "react";

export class AddPolicyType extends Component {
  render() {
    return (
      <div>
        <div class="pcoded-main-container">
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Add Policy Type</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!"> Policy Type: list</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!"> Policy Types</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <form method="post" class="reservation-form">
                      <div class="reservation-detais form-inner">
                        <div class="row form-wrap">
                          <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                            <input type="text" placeholder="Policy Type Title" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                            <input type="text" placeholder="Policy Type " style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                        <button type="submit" class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                          Create Policy Type
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <div class="pcoded-main-container" style={{ textAlign: "left" }}>
            <div class="pcoded-content">
              <div class="page-header">
                <div class="page-block">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="page-header-title">
                        <h5 class="m-b-10">Add Policy Type</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-block">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                    <h5 class="m-b-10" style={{ marginLeft: "20px" }}>
                      {" "}
                      Dashboard
                    </h5>
                    <li class="m-b-10" style={{ marginLeft: "20px" }}>
                      <a href="#!">Policy Types:List</a>
                    </li>
                    <li class="m-b-10" style={{ marginLeft: "20px" }}>
                      <a href="#!">Policy Types</a>
                    </li>
                  </div>

                  <div class="col-md-12">
                    <div class="card" style={{ borderRadius: "15px" }}>
                      <div class="card-body">
                        <form method="post" class="reservation-form">
                          <div class="reservation-detais form-inner">
                            <div class="row form-wrap">
                              <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                                <input type="text" placeholder="Policy Type Title" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                              </div>
                              <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                                <input type="text" placeholder="Policy Type " style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                            <button type="submit" class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                              Create Policy Type
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default AddPolicyType;
