import React, { Component } from 'react'
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import withRouter from '../../Services/withRouter'

export class EditDayTime extends Component {
  componentDidMount = async()=>{
        if(this.props.params.id){
            this.getDayTime();
        }
  } 
  
  getDayTime = async () => {
    var data = {
      id: this.props.params.id,
    };

    var response = await Api.GetRequest(data, 'day-time/edit');
    if (response.status == 200) {
      await this.setState({
        
        "day_type": response.day_time.day_type,
        "start_time": response.day_time.start_time,
        "end_time":  response.day_time.end_time,
        
      });
    }
  };

  day_types = [
    { 'value': 0, 'label': 'Day' },
    { 'value': 1, 'label': 'Night' }
  ]

  constructor(props) {
    super(props);

    this.state = {
        'day_type':"",
        'start_time':"",
        'end_time':"",
        
      
    };
  }

  updateDayList = async (event) => {
    var data = {
      'id': this.props.params.id,
      'day_type': this.state.day_type,
      'start_time': this.state.start_time,
      'end_time': this.state.end_time,
      
      
      
      
    };
    var response = await Api.PostRequest(data, "day-time/edit");
    if (response.status === 200) {
      toast.success(response.message);

      this.props.navigate("/rates/day-times");
    } else {
      toast.error(response.message);
    }
  };


  render() {
    return (
        <div>
            <div class="pcoded-main-container" style={{ textAlign: "left" }}>
                <div class="pcoded-content">
                    <div class="page-header">
                        <div class="page-block">
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <div class="page-header-title">
                                        <h5 class="m-b-10">Edit cancel Reasons</h5>
                                    </div>
                                    <ul class="breadcrumb">
                                        <li class="breadcrumb-item">
                                            
                                         <i class="feather icon-home"></i>
                                         
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#!">Cancel Reasons</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#!"> Edit cancel Reasons</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="reservation-detais form-inner">
                                        <div class="row form-wrap">
                                
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                <input type="text" name="time" placeholder=" start time-hh:mm:aa" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.start_time} onChange={(event) => this.setState({ start_time: event.target.value })} />
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                <input type="text" name="time" id="time" placeholder="End Time-hh:mm:aa" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.end_time} onChange={(event) => this.setState({ end_time: event.target.value })} />
                                            </div>
                                            <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                                                <Select value={this.day_types[this.state.day_type]} options={this.day_types} onChange={(event) => { this.setState({ 'day_type': event.value }) }} />
                                            </div>

                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                                        <button type="submit" onClick={() => { this.updateDayList()}} class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                                            Create cancel Reason
                                        </button>
                                    </div>
                                </div> 
                            </div>       
                        </div>
                        
                    </div>        
                </div>       
            </div>      
                
        </div>       
    )        
  }       
}     
export default withRouter(EditDayTime)
  



