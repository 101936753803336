import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";

export class AdditionalCharges extends Component {
  componentDidMount = async () => {
    this.getAdditionalCharges();
  };


  getAdditionalCharges = async () => {
    var response = await Api.GetRequest({}, "additional-charges");
    if (response.status == 200) {
      console.log(response);
      this.setState({ additionalcharges: response.additional_charges});
    } else {
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      additionalcharges: [],
    };
  }
 
  render() {
    return (
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <Link to="/rates/add-additional-charges">
            <button   type="submit" class="btn btn-success" >
              <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add Additional Charges
            </button>
          </Link>
        </div>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Additional Charges</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">rates</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!"> Additional Charges</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="dt-responsive table-responsive">
                      <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                        <thead>
                          <tr>
                            <td colSpan="7" style={{ border: "none" }}>
                              <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                            </td>
                          </tr>
                        </thead>

                        <thead>
                          <tr>
                            <th style={{ border: "none" }}>S.N</th>
                            <th style={{ border: "none" }}>Title</th>
                            <th style={{ border: "none" }}>Value</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.additionalcharges.map((charge, index) => {
                          return (
                            <tr>
                              <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                              
                              <td style={{ verticalAlign: "middle" }}>{charge.title}</td>
                              <td style={{ verticalAlign: "middle" }}>{charge.price} </td>
                                
                              
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}

export default AdditionalCharges;
