import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify"

export class DayTimes extends Component {
  componentDidMount = async () => {
    this.getDayTimes();
  };

  getDayTimes = async () => {
    var response = await Api.GetRequest({}, "day-times");
    if (response.status == 200) {
      console.log(response);
      this.setState({ days: response.dayTimes});
    } else {
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      days: [],
    };
  }
  render() {
    return (
      <div>
        <div className="pcoded-main-container" style={{ textAlign: "left" }}>
          <div className="pcoded-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">Day imes List</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">
                          <i className="feather icon-home"></i>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#!">rates</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#!"> Day imes List</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dt-responsive table-responsive">
                      <table id="full-row-reorder" className="table table-striped table-bordered nowrap">
                        <thead>
                          <tr>
                            <td colSpan="7" style={{ border: "none" }}>
                              <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                            </td>
                          </tr>
                        </thead>

                        <thead>
                          <tr>
                            <th style={{ border: "none" }}> ID</th>
                            <th style={{ border: "none" }}>Day Type</th>
                            <th style={{ border: "none" }}>Start Time</th>
                            <th style={{ border: "none" }}>End Time</th>
                            <th style={{ border: "none" }}>Created at</th>
                            <th style={{ border: "none" }}>Updated at at</th>
                            <th style={{ border: "none" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.days.map((day, index) => {
                              return (
                                <tr>
                                  <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                                  <td style={{ verticalAlign: "middle" }}>{day.day_type}</td>
                                  <td style={{ verticalAlign: "middle" }}>{day.start_time}</td>
                                  <td style={{ verticalAlign: "middle" }}>{day.end_time}</td>
                                  <td style={{ verticalAlign: "middle" }}>{day.created_at}</td>
                                  <td style={{ verticalAlign: "middle" }}>{day.updated_at}</td>
                                  
                                  <td className="text-right">
                                    <Link  to={`/rates/edit-day-times/${day.id}`}className="btn btn-info btn-sm mx-2">
                                      <i className="feather icon-edit"></i>&nbsp;Edit
                                    </Link>

                                    {/* <button className="btn btn-danger btn-sm mx-2" >
                                      <i className="feather icon-trash-2"></i>&nbsp;Delete
                                    </button> */}
                                  </td>
                                </tr>
                              );
                          })} 
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pcoded-main-container" style={{ textAlign: "left" }}>
          <div className="pcoded-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10"> Day imes List</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                  <h5 className="m-b-10" style={{ marginLeft: "20px" }}>
                    {" "}
                    Dashboard
                  </h5>
                  <li className="m-b-10" style={{ marginLeft: "20px" }}>
                    <a href="#!">Day Times</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: "10px", marginRight: "20px" }}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <table id="full-row-reorder" className="table table-striped table-bordered nowrap">
                      <thead>
                        <tr>
                          <td colSpan="7" style={{ border: "none" }}>
                            <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </td>
                        </tr>
                      </thead>

                      <thead>
                        <tr>
                          <th style={{ border: "none" }}> ID</th>
                          <th style={{ border: "none" }}>Day Type</th>
                          <th style={{ border: "none" }}>Start Time</th>
                          <th style={{ border: "none" }}>End Time</th>
                          <th style={{ border: "none" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1234</td>
                          <td>2024/04/16</td>
                          <td>unpaid</td>
                          <td>unpaid</td>
                          <td>unpaid</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default DayTimes;
