const GetToken = async () => {
  var token = "";
  token = (await localStorage.getItem("token")) || "";
  return token;
};

const GetUser = async () => {
  var user = {};
  user = (await localStorage.getItem("user")) || {};
  return user;
};

export default { GetToken, GetUser };
