import React, { Component } from "react";

export class Trips extends Component {
  render() {
    return (
      <div>
        <div>
          <div class="pcoded-main-container" style={{ textAlign: "left" }}>
            <div class="pcoded-content">
              <div class="page-header">
                <div class="page-block">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="page-header-title">
                        <h5 class="m-b-10">Trip Report</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Trips;
