import React, { Component } from "react";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import withRouter from '../../Services/withRouter'


export class EditDriverPartner extends Component {


	componentDidMount = async()=>{
		if(this.props.params.id){
			this.getDriverPartner();
		}
	}


	getDriverPartner = async()=>{
		var data = {
			'id' : this.props.params.id
		}
		var response = await Api.GetRequest(data, 'driver-partner/edit');
		if(response.status == 200){
			await this.setState({
				'name': response.driver_partner.name,
				'mobile': response.driver_partner.mobile,
				'email': response.driver_partner.email,
				'gender': response.driver_partner.gender,
				"licensce_no": response.driver_partner.licensce_no,
				"date_of_birth": response.driver_partner.date_of_birth,
				"experience": response.driver_partner.experience,
				"location": response.driver_partner.location,
				"house_number": response.driver_partner.house_number,
				"pincode": response.driver_partner.pincode,
				"uniform_type": response.driver_partner.uniform_type,
				"station_type": response.driver_partner.station_type,
				"vehicle_models": response.driver_partner.vehicle_models,
				"trip_type": response.driver_partner.trip_type,
				"transmission_type": response.driver_partner.transmission_type,
				"status": response.driver_partner.status
			});
			this.getVehicleModelsDefaultValue();
		}
	}


	gender_list = [
		{ 'value': 0, 'label': 'Male' },
		{ 'value': 1, 'label': 'Female' }
	]


	uniform_types = [
		{ 'value': 0, 'label': 'No' },
		{ 'value': 1, 'label': 'Yes' }
	]


	station_types = [
		{ 'value': 0, 'label': 'In station' },
		{ 'value': 1, 'label': 'Out Station' },
		{ 'value': 2, 'label': 'Both' }
	]


	vehicle_models = [
		{ 'value': 0, 'label': 'SUV' },
		{ 'value': 1, 'label': 'Sedan' },
		{ 'value': 2, 'label': 'Luxury' },
		{ 'value': 3, 'label': 'Hatchback' }
	]

	trip_types = [
		{ 'value': 0, 'label': 'One way' },
		{ 'value': 1, 'label': 'Round' },
		{ 'value': 2, 'label': 'Both' },
	]


	transmission_types = [
		{ 'value': 0, 'label': 'Automatic' },
		{ 'value': 1, 'label': 'Manual' },
		{ 'value': 2, 'label': 'Both' },
	]

	status_list = [
		{ 'value': 0, 'label': 'In Active' },
		{ 'value': 1, 'label': 'Active' },
	]

	constructor(props) {
		super(props);

		this.state = {
			'name': "",
			'mobile': "",
			'email': "",
			'gender': 0,
			"licensce_no": "",
			"date_of_birth": "",
			"experience": "",
			"location": "",
			"house_number": "",
			"pincode": "",
			"uniform_type": "",
			"station_type": "",
			"vehicle_models": [],
			"default_vehicle_models" : [],
			"trip_type": "",
			"transmission_type": "",
			"status": ""
		};
	}




	updateDriverPartners = async (event) => {
		var data ={
			"id" : this.props.params.id,
			"name": this.state.name,
			"mobile": this.state.mobile,
			"email": this.state.email,
			"gender": this.state.gender.toString(),
			"licensce_no": this.state.licensce_no,
			"date_of_birth": this.state.date_of_birth,
			"experience": this.state.experience,
			"location": this.state.location,
			"house_number": this.state.house_number,
			"pincode": this.state.pincode,
			"uniform_type": this.state.uniform_type,
			"station_type": this.state.station_type,
			"vehicle_models": this.state.vehicle_models,
			"trip_type": this.state.trip_type,
			"transmission_type": this.state.transmission_type,
			"status": this.state.status != "" ? this.state.status : 0
		}
		var response = await Api.PostRequest(data, "driver-partner/edit");
		if (response.status === 200) {
			toast.success(response.message);
			
			this.props.navigate('/driver-partner/list')
		} 
		else {
			toast.error(response.message);
		}
	};



	setVehicleModels = async (selected_vehicle_models) => {
		var vehicle_models = [];

		await selected_vehicle_models.forEach((vehicle) => {
			vehicle_models.push(vehicle.value);
		});

		await this.setState({'vehicle_models': vehicle_models });
		await this.getVehicleModelsDefaultValue();
	}	


	getVehicleModelsDefaultValue = async()=>{
		var vehicle_models = [];
		await this.state.vehicle_models.forEach(async(model)=>{
			await vehicle_models.push(this.vehicle_models[model])
		});
		this.setState({'default_vehicle_models' : vehicle_models})
	}

	render() {
		return (
			<div>
				<div className="pcoded-main-container" style={{ textAlign: "left" }}>
					<div className="pcoded-content">
						<div className="page-header">
							<div className="page-block">
								<div className="row align-items-center">
									<div className="col-md-12">
										<div className="page-header-title">
											<h5 className="m-b-10"> Create A New Driver</h5>
										</div>
										<ul className="breadcrumb">
											<li className="breadcrumb-item">
												<a href="index.html">
													<i className="feather icon-home"></i>
												</a>
											</li>
											<li className="breadcrumb-item">
												<a href="#!">Driver partner</a>
											</li>
											<li className="breadcrumb-item">
												<a href="#!">Create Driver partner</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12">
								<div className="card">
									<div className="card-body">
										<div id="collected-chart">
											<div className="reservation-page-section">
												<div className="container">
														<div className="reservation-detais form-inner">
															<div className="row form-wrap">

																<div className="col-lg-4 col-md-4 col-sm-4 col-12 my-4 form-group">
																	<label>Phone No.</label>
																	<input type="tel" name="phone" id="phone" placeholder="" className="form-control" value={this.state.mobile} onChange={(event) => { this.setState({ 'mobile': event.target.value }) }} />
																</div>

																<div className="col-lg-4 col-md-4 col-sm-4 col-12 my-4 form-group">
																	<label>Name</label>
																	<input type="text" name="name" id="name" className="form-control" placeholder="" value={this.state.name} onChange={(event) => { this.setState({ 'name': event.target.value }) }} />
																</div>

																<div className="col-lg-4 col-md-4 col-sm-4 col-12 my-4 form-group">
																	<label>Gender</label>
																	<Select value={this.gender_list[this.state.gender]} options={this.gender_list} onChange={(event) => { this.setState({'gender': event.value}) }} />
																</div>


																<div className="col-lg-4 col-md-4 col-sm-4 col-12 my-4 form-group">
																	<label>Email</label>
																	<input type="email" name="email" id="email" placeholder="" value={this.state.email} className="form-control" onChange={(event) => { this.setState({ 'email': event.target.value }) }} />
																</div>

																<div className="col-lg-4 col-md-4 col-sm-4 col-12 my-4 form-group">
																	<label>Licance no.</label>
																	<input type="text" name="licance_no" id="licance_no" placeholder="" className="form-control" value={this.state.licensce_no} onChange={(event) => { this.setState({ 'licensce_no': event.target.value }) }} />
																</div>

																<div className="col-lg-4 col-md-4 col-sm-4 col-12 my-4 form-group">
																	<label>Date Of Birth</label>
																	<input type="date" name="date_of_birth" id="date_of_birth" placeholder="" className="form-control" value={this.state.date_of_birth} onChange={(event) => { this.setState({ 'date_of_birth': event.target.value }) }} />
																</div>

																<div className="col-lg-4 col-md-4 col-sm-4 col-12 my-4 form-group">
																	<label>Experience</label>
																	<input type="text" name="exeprience" id="exeprience" placeholder="" className="form-control" value={this.state.experience} onChange={(event) => { this.setState({ experience: event.target.value }) }} />
																</div>

																<div className="col-lg-4 col-md-4 col-sm-4 col-12 my-4 form-group">
																	<label>Status</label>
																	<Select value={this.status_list[this.state.status]} options={this.status_list} onChange={(event) => { this.setState({'status': event.value})}} />
																</div>
															</div>
														</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="card" style={{ borderRadius: "15px" }}>
										<div className="card-body">
											<div id="collected-chart">
												<div className="reservation-page-section">
													<div className="container">
															<div className="reservation-detais form-inner">
																<div className="row form-wrap">
																	<div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
																		<h5> Address</h5>
																	</div>
																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Location</label>
																		<input type="text" name="location" id="location" className="form-control" placeholder="location" value={this.state.location} onChange={(event) => { this.setState({ 'location': event.target.value }) }} />
																	</div>

																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>House Number</label>
																		<input type="text" name="house_number" id="house_number" placeholder="" className="form-control" value={this.state.house_number} onChange={(event) => { this.setState({ 'house_number': event.target.value }) }} />
																	</div>

																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Pincode</label>
																		<input type="text" name="Pincode" id="pincode" placeholder="pincode" className="form-control" value={this.state.pincode} onChange={(event) => { this.setState({ 'pincode': event.target.value }) }} />
																	</div>
																</div>
															</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="card" style={{ borderRadius: "15px" }}>
										<div className="card-body">
											<div id="collected-chart">
												<div className="reservation-page-section">
													<div className="container">
															<div className="reservation-detais form-inner">
																<div className="row form-wrap">
																	<div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
																		<h5>Preferences </h5>
																	</div>
																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Uniform</label>
																		<Select value={this.uniform_types[this.state.uniform_type]}  options={this.uniform_types} onChange={(event) => { this.setState({ 'uniform_type': event.value }) }} />
																	</div>

																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Station Type</label>
																		<Select value={this.station_types[this.state.station_type]} options={this.station_types} onChange={(event) => { this.setState({ 'station_type': event.value }) }} />
																	</div>

																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Vechicle Model</label>
																		{/* <Select options={this.vehicle_models} isMulti={true} onChange={(event)=>{this.setState({'vehicle_models' : event.value})}}/> */}
																		<Select value={this.state.default_vehicle_models} options={this.vehicle_models} isMulti={true} onChange={(event) => { this.setVehicleModels(event) }} />
																	</div>

																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Trip Type</label>
																		<Select value={this.trip_types[this.state.trip_type]} options={this.trip_types} onChange={(event) => { this.setState({ 'trip_type': event.value }) }} />
																	</div>

																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Transmission Type</label>
																		<Select value={this.transmission_types[this.state.transmission_type]} options={this.transmission_types} onChange={(event) => { this.setState({ 'transmission_type': event.value }) }} />
																	</div>
																</div>
															</div>
															<div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center align-items-center d-flex">
																<button type="button" onClick={()=>{this.updateDriverPartners()}} className="btn btn-success">Create Driver</button>
															</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(EditDriverPartner);
