import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import withRouter from "../../Services/withRouter";

export class EditVehicle extends Component {
    componentDidMount = async () => {
        if (this.props.params.id) {
          this.getVehicle();
        }
      };

      getVehicle = async () => {
        var data = {
          id: this.props.params.id,
        };
    
        var response = await Api.GetRequest(data, 'vehicle-type/edit');
        if (response.status == 200) {
            await this.setState({
                'name': response.vehicle_type.name
            })   
        };
      }   

      constructor(props) {
        super(props);
    
        this.state = {
          'name':""
        };
      }
    
    
    
      updateVehicle = async (event) => {
        var data = {
          'id': this.props.params.id,
          'name': this.state.name,
          
        };
        var response = await Api.PostRequest(data, "vehicle-type/edit");
        if (response.status === 200) {
          toast.success(response.message);
    
          this.props.navigate("/vechicles/type");
        } else {
          toast.error(response.message);
        }
      };
  render() {
    return (
      <div>
        
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Vechicles Types List</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Vechicles</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Vechicles Types List </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="reservation-page-section">
              <div class="col-md-12">
                <div class="card" style={{ borderRadius: "15px" }}>
                  <div class="card-body">
                    <div class="container">
                      
                        <div class="reservation-detais form-inner">
                          <div class="row form-wrap">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                              <input type="text" name="name" id="name" placeholder="Vehicle Model Name" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.name} onChange={(event) => {  this.setState({ name: event.target.value })}}/>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                          <button type="submit" class="btn btn-success" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}onClick={() => { this.updateVehicle()}}>
                            update Vehicle Type
                          </button>
                        </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EditVehicle)
