import React, { Component } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import Select from 'react-select';
import api from "../../Services/api";



export class TripList extends Component {


	status = [
		{'value' : 0, 'label' : 'Pending'},
		{'value' : 1, 'label' : 'Assigned'},
		{'value' : 2, 'label' : 'Start'},
		{'value' : 3, 'label' : 'Completed'},
		{'value' : 4, 'label' : 'Rejected'},
	]

	componentDidMount = async()=>{
		this.getTrips();
		this.getDriverPartners();
	}



	getDriverPartners = async ()=>{
		var response = await Api.GetRequest({}, "driver-partners/list");
		if(response.status == 200){
			this.setState({'driver_partners' : response.driver_partners});
		}
		else{
			
		}
	}


	getTrips = async()=>{
		var response = await Api.GetRequest({}, "trips");
		if(response.status == 200){
			this.setState({'trips' : response.trips});
		}
		else{
			
		}
	}

	constructor(props){
		super(props);
		this.state = {
			'section' : "0",
			'trips' : [],
			'driver_partners' : []
		}
	}


	assignTripToDriver = async(trip, driver)=>{
		var data = {
			'trip' : trip,
			'driver' : driver.value
		}

		var response = await api.PostRequest(data, 'trip/assign');
		if(response.status == 200){
			toast.success("Driver assigned successfully.");
			this.getTrips();
		}
		else{
			toast.error("error");
		}
	}


	changeTripStatus = async(trip, status)=>{
		var data = {
			'trip' : trip,
			'status' : status.value
		}

		var response = await api.PostRequest(data, 'trip/status');
		if(response.status == 200){
			toast.success("Status changed successfully.");
			this.getTrips();
		}
		else{
			toast.error("error");
		}
	}


	editTrip = async(id)=>{
		this.props.navigate('/trips/edit-trip/'+id)
	}

	render() {
		return (
			
			<div className="pcoded-main-container">
				<div className="pcoded-content">
					<div className="page-header">
						<div className="page-block">
							<div className="row align-items-center">
								<div className="col-md-12">
									<div className="page-header-title">
										<h5 className="m-b-10 text-left">Trips List</h5>
									</div>
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><a href="index.html"><i className="feather icon-home"></i></a></li>
										<li className="breadcrumb-item"><a href="#!">Trips</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									{/* <Tabs id="controlled-tab-example"  defaultActiveKey="0" activeKey={this.state.section} onSelect={(section) => {this.setState({'section' : section})}} className="mb-3">
										<Tab eventKey="0" title="Home"></Tab>
										<Tab eventKey="1" title="Profile"></Tab>
										<Tab eventKey="2" title="Contact"></Tab>
									</Tabs> */}
									<div className="dt-responsive table-responsive">
										<table  className="table table-striped table-responsive table-bordered">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Customer</th>
													<th>Trip Type</th>
													<th>Trip Date & Time</th>
													<th>Payment Status</th>
													{/* <th>Amount</th> */}
													<th>Net Amount</th>
													<th>Status</th>
													<th>Driver</th>
													<th>Created At</th>
													<th>Action</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.trips.map((trip, index)=>{
													return(
														<tr>
															<td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                                							<td style={{ verticalAlign: "middle" }}>
																{trip.mobile}<br></br>
																{trip.name}
															</td>
                                							<td style={{ verticalAlign: "middle" }}>
																{trip.trip_type == 0 &&
																	<span>One way</span>
																}
																{trip.trip_type == 1 &&
																	<span>Round</span>
																}
																<br></br>
																{trip.station_type == 0 &&
																	<span>Instation</span>
																}
																{trip.station_type == 1 &&
																	<span>Outstation</span>
																}
															</td>


															<td style={{ verticalAlign: "middle", 'textAlign' : 'left'}}>
																{!trip.trip_start_date && !trip.trip_end_date &&
																	<>
																		{moment(trip.start_date).format("DD-MM-YYYY")} {trip.start_time} <br></br> {moment(trip.end_date).format("DD-MM-YYYY")} {trip.end_time}
																	</>
																}
																{trip.trip_start_date && !trip.trip_end_date &&
																	<>
																		{moment(trip.trip_start_date).format("DD-MM-YYYY")} {trip.trip_start_time} <br></br> {moment(trip.end_date).format("DD-MM-YYYY")} {trip.end_time}
																	</>
																}

																{trip.trip_start_date && trip.trip_end_date &&
																	<>
																		{moment(trip.trip_start_date).format("DD-MM-YYYY")} {trip.trip_start_time} <br></br> {moment(trip.trip_end_date).format("DD-MM-YYYY")} {trip.trip_end_time}
																	</>
																}
																
															</td>


															<td style={{ verticalAlign: "middle", 'textAlign' : 'right'}}>
																{trip.payment_status == 0 &&
																	<span class="badge badge-danger">Pending</span>
																}
																{trip.payment_status == 1 &&
																	<span class="badge badge-success">Complete</span>
																}
															</td>
															
															{/* <td style={{ verticalAlign: "middle", textAlign:'right'}}> ₹ {trip.amount}</td> */}
															<td style={{ verticalAlign: "middle", textAlign:'right'}}> ₹ {trip.net_amount}</td>

															<td>
																<Select 
																	menuPortalTarget={document.body}
																	value = {
																		this.status.filter(option => 
																			option.value == trip.status)
																		} 
																	onChange={(event) => {this.changeTripStatus(trip.id, event)}} 
																	options={this.status}/>
															</td>
															<td>
																<Select 
																	menuPortalTarget={document.body}
																	value = {
																		this.state.driver_partners.filter(option => 
																			option.value == trip.driver_id)
																		} 
																	onChange={(event) => {this.assignTripToDriver(trip.id, event)}} 
																	options={this.state.driver_partners}/>
															</td>
															<td style={{ verticalAlign: "middle" }}>{moment(trip.created_at).format('lll')}</td>
															<td style={{ verticalAlign: "middle" }}>

																<Link to={`/trips/edit/${trip.id}`} className="btn btn-info btn-sm mx-2">
																	<i className="feather icon-edit"></i>&nbsp;Edit
																</Link>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
			 						</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TripList;
