import logo from "./logo.svg";
import "./App.css";
import Api from "./Services/api";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import { AuthContext } from './utils/AuthContext';



import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Dashboard from "./components/dashboard/Dashboard";
import Default from "./components/default/Default";
import Notifications from "./components/notifications/Notifications";
import Profile from "./components/profile/Profile";
import TripList from "./components/trips/TripList";
import CreateNewTrip from "./components/trips/CreateNewTrip";
import EditTrip from "./components/trips/EditTrip";
import DriverPartnerList from "./components/driver-partner/DriverPartnerList";
import CreateDriverPartner from "./components/driver-partner/CreateDriverPartner";
import DriverPartnerEdit from "./components/driver-partner/DriverPartnerEdit";
import PendingDriverPartnerList from "./components/driver-partner/PendingDriverPartnerList";
import Login from "./components/login/Login";
import CustomerList from "./components/customers/CustomerList";
import UserList from "./components/admin_users/UserList";
import AddNewUser from "./components/admin_users/AddNewUser";
import RoleList from "./components/manage-role/RoleList";
import RatesList from "./components/rates/RatesList";
import AdditionalCharges from "./components/rates/AdditionalCharges";
import Taxation from "./components/rates/Taxation";
import AddTaxation from "./components/rates/AddTaxation";
import DayTimes from "./components/rates/DayTimes";
import CouponList from "./components/coupons/CouponList";
import AddCouponList from "./components/coupons/AddCouponList";
import CancelReasonsList from "./components/cancel_reasons/CancelReasonsList";
import AddCancelReasons from "./components/cancel_reasons/AddCancelReasons";
import PolicyTypeList from "./components/policy_type/PolicyTypeList";
import AddPolicyType from "./components/policy_type/AddPolicyType";
import TripHourList from "./components/trip_hour/TripHourList";
import VechiclesType from "./components/vechicles/VechiclesType";
import VechicleModel from "./components/vechicles/VechicleModel";
import TransmissionType from "./components/vechicles/TransmissionType";
import ChatBoxResponses from "./components/settings/ChatBoxResponses";
import ServiceAreaConfiguration from "./components/settings/ServiceAreaConfiguration";
import LegalDocument from "./components/help_and_support/LegalDocument";
import Faqs from "./components/help_and_support/Faqs";
import Customers from "./components/reports/Customers";
import Drivers from "./components/reports/Drivers";
import Trips from "./components/reports/Trips";
import CouponDataEdit from "./components/coupons/CouponDataEdit";
import VehicleTypeEdit from "./components/vechicles/VehicleTypeEdit";
import VehicleModelEdit from "./components/vechicles/VehicleModelEdit";
import AddAdditionalCharges from "./components/rates/AddAdditionalCharges";
import TaxationEdit from "./components/rates/TaxationEdit";
import RateListEdit from "./components/rates/RateListEdit";
import DayTimeEdit from "./components/rates/DayTimeEdit";

function App() {


	useEffect(() => {
		getUser();
	})



	const getUser = async () => {
		var token = (await localStorage.getItem("token")) || "";
		if (token) {
			setIsLoggedIn(true)
		}
		else {
			setIsLoggedIn(false)
		}
	}


	const [loggedIn, setIsLoggedIn] = React.useState(false);


	const authContext = React.useMemo(
		() => ({
			signIn: async () => {
				await setIsLoggedIn(true)
			},
			signOut: async () => {
				await setIsLoggedIn(false)
			}
		}),
	);




	return (

		<AuthContext.Provider value={authContext}>
			<div className="App">
				{loggedIn == false && (
					<Routes>
						<Route path="/" exact element={<Login />} />
					</Routes>
				)}
				{loggedIn == true && (
					<>
						<Header />
						<Routes>
							<Route path="/dashboard" exact element={<Dashboard />} />
							<Route path="/" exact element={<Dashboard />} />
							<Route path="/default" exact element={<Default />} />

							<Route path="/notifications" exact element={<Notifications />} />

							<Route path="/profile" exact element={<Profile />} />
							<Route path="/trips/list" exact element={<TripList />} />
							<Route path="/trips/create-new" exact element={<CreateNewTrip />} />
							<Route path="/trips/edit/:id" exact element={<EditTrip />} />

							<Route path="/driver-partner/list" exact element={<DriverPartnerList />} />
							<Route path="/driver-partner/create" exact element={<CreateDriverPartner />} />
							<Route path="/driver-partner/edit/:id" exact element={<DriverPartnerEdit />} />
							<Route path="/driver-partner/pending" exact element={<PendingDriverPartnerList />} />

							<Route path="/customers/list" exact element={<CustomerList />} />

							<Route path="/admin_users/list" exact element={<UserList />} />
							<Route path="/admin_users/add-new" exact element={<AddNewUser />} />
							<Route path="/manage-role/list" exact element={<RoleList />} />

							<Route path="/rates/list" exact element={<RatesList />} />
							<Route path="/rates/edit-list/:id" exact element={<RateListEdit />} />
							<Route path="/rates/additional-charges" exact element={<AdditionalCharges />} />
							<Route path="/rates/add-additional-charges" exact element={<AddAdditionalCharges />} />
							<Route path="/rates/taxation" exact element={<Taxation />} />
							<Route path="/rates/AddTaxation" exact element={<AddTaxation />} />
							<Route path="/rates/edit-tax/:id" exact element={<TaxationEdit />} />
							<Route path="/rates/day-times" exact element={<DayTimes />} />
							<Route path="/rates/edit-day-times/:id" exact element={<DayTimeEdit />} />

							<Route path="/coupons/list" exact element={<CouponList />} />
							<Route path="/coupons/edit/:id" exact element={<CouponDataEdit />} />
							<Route path="/coupons/add-new-coupon" exact element={<AddCouponList />} />
							<Route path="/cancel_reasons/list" exact element={<CancelReasonsList />} />
							<Route path="/cancel_reasons/add-cancel-reasons" exact element={<AddCancelReasons />} />

							<Route path="/policy_type/list" exact element={<PolicyTypeList />} />
							<Route path="/policy_type/add-policy-type" exact element={<AddPolicyType />} />

							<Route path="/trip_hour/list" exact element={<TripHourList />} />

							<Route path="/vechicles/type" exact element={<VechiclesType />} />
							<Route path="/vechicles/edit/:id" exact element={<VehicleTypeEdit />} />
							<Route path="/vechicles/model" exact element={<VechicleModel />} />
							<Route path="/vechicles/model-edit/:id" exact element={<VehicleModelEdit />} />
							<Route path="/vechicles/transmission-type" exact element={<TransmissionType />} />

							<Route path="/settings/responses" exact element={<ChatBoxResponses />} />
							<Route path="/settings/configuration" exact element={<ServiceAreaConfiguration />} />

							<Route path="/help_and_support/legal" exact element={<LegalDocument />} />
							<Route path="/help_and_support/faqs" exact element={<Faqs />} />

							<Route path="/reports/customers" exact element={<Customers />} />
							<Route path="/reports/drivers" exact element={<Drivers />} />
							<Route path="/reports/trips" exact element={<Trips />} />
						</Routes>
					</>
				)}
				<ToastContainer />
				<Footer />
			</div>
		</AuthContext.Provider>
	);
}

export default App;
