import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../header/Header";

export class Dashboard extends Component {

	constructor(props) {
		super(props)
	}

	render() {
		return (
			<>
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-12">
										<div class="page-header-title">
											<h5 class="m-b-10">Dashboard</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Membership</a></li>
											<li class="breadcrumb-item"><a href="#!">Dashboard</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>


						<div class="row">
							<div class="col-lg-4 col-md-6">
								<div class="card">
									<div class="card-body">
										<div class="row align-items-center">
											<div class="col-8">
												<h4 class="text-c-yellow">$30200</h4>
												<h6 class="text-muted m-b-0">All Earnings</h6>
											</div>
											<div class="col-4 text-right">
												<i class="feather icon-bar-chart-2 f-28"></i>
											</div>
										</div>
									</div>
									<div class="card-footer bg-c-yellow">
										<div class="row align-items-center">
											<div class="col-9">
												<p class="text-white m-b-0">% change</p>
											</div>
											<div class="col-3 text-right">
												<i class="feather icon-trending-up text-white f-16"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6">
								<div class="card">
									<div class="card-body">
										<div class="row align-items-center">
											<div class="col-8">
												<h4 class="text-c-green">290+</h4>
												<h6 class="text-muted m-b-0">Page Views</h6>
											</div>
											<div class="col-4 text-right">
												<i class="feather icon-file-text f-28"></i>
											</div>
										</div>
									</div>
									<div class="card-footer bg-c-green">
										<div class="row align-items-center">
											<div class="col-9">
												<p class="text-white m-b-0">% change</p>
											</div>
											<div class="col-3 text-right">
												<i class="feather icon-trending-up text-white f-16"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6">
								<div class="card">
									<div class="card-body">
										<div class="row align-items-center">
											<div class="col-8">
												<h4 class="text-c-red">145</h4>
												<h6 class="text-muted m-b-0">Task</h6>
											</div>
											<div class="col-4 text-right">
												<i class="feather icon-calendar f-28"></i>
											</div>
										</div>
									</div>
									<div class="card-footer bg-c-red">
										<div class="row align-items-center">
											<div class="col-9">
												<p class="text-white m-b-0">% change</p>
											</div>
											<div class="col-3 text-right">
												<i class="feather icon-trending-down text-white f-16"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-sm-12">
												<div class="card">
													<div class="card-header">
														<h5>Full Row Selection</h5>
													</div>
													<div class="card-body">
														<div class="dt-responsive table-responsive">
															<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
																<thead>
																	<tr>
																		<th>All</th>
																		<th>Requested Trips</th>
																		<th>Upcoming Trips</th>
																		<th>Asigned Trips</th>
																		<th>Ongoing Trips</th>
																		<th>Payment Processing</th>
																		<th>Completed Trips</th>
																		<th>Cancelled Trips</th>
																	</tr>
																</thead>
																<thead>
																	<tr>
																		<th>Otp</th>
																		<th>Date</th>
																		<th>Paid</th>
																		<th>Customer</th>
																		<th>Driver Asigned</th>
																		<th>Trip Time</th>
																		<th>Status</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>1234</td>
																		<td>2024/04/16</td>
																		<td>unpaid</td>
																		<td>61</td>
																		<td>john</td>
																		<td>7:30pm</td>
																		<td>eztimate created</td>
																	</tr>
																	<tr>
																		<td>1234</td>
																		<td>2024/04/25</td>
																		<td></td>
																		<td>62</td>
																		<td>johne</td>
																		<td>7:30am</td>
																		<td>cancelled</td>
																	</tr>
																	<tr>
																		<td>1234</td>
																		<td>2011/04/25</td>
																		<td>unpaid</td>
																		<td>61</td>
																		<td>john</td>
																		<td>7:30pm</td>
																		<td>eztimate created</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>

		);
	}

}

export default Dashboard;
