import React, { Component } from "react";

export class AddNewUser extends Component {
  render() {
    return (
      <div>
        <div class="pcoded-main-container">
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Create A New User</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Admin User</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">New User</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div id="collected-chart">
                      <div class="reservation-page-section">
                        <div class="container">
                          <form method="post" class="reservation-form">
                            <div class="reservation-detais form-inner">
                              <div class="row form-wrap">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="text" name="name" id="name" placeholder="Full Name" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="email" name="email" id="email" placeholder="Email Address" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="tel" name="phone-no" id="address" placeholder="Phone Number" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <select style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}>
                                    <option value="">Select Role</option>
                                    <option value="option1">option1</option>
                                  </select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="password" name="Password" id="Password" placeholder="Password" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                              <button type="submit" class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                                Create User
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h4 class="m-b-10">Create a new user</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                <h5 class="m-b-10" style={{ marginLeft: "20px" }}>
                  {" "}
                  Dashboard
                </h5>
                <li class="m-b-10" style={{ marginLeft: "20px" }}>
                  <a href="#!">User</a>
                </li>
                <li class="m-b-10" style={{ marginLeft: "20px" }}>
                  <a href="#!">New User</a>
                </li>
              </div>
            </div>
          </div>
          <div class="row" style={{ marginTop: "50px", marginLeft: "10px", marginRight: "10px" }}>
            <div class="row" style={{ width: "100%" }}>
              <div class="col-md-12">
                <div class="card" style={{ borderRadius: "15px" }}>
                  <div class="card-body">
                    <div id="collected-chart">
                      <div class="reservation-page-section">
                        <div class="container">
                          <form method="post" class="reservation-form">
                            <div class="reservation-detais form-inner">
                              <div class="row form-wrap">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="text" name="name" id="name" placeholder="Full Name" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="email" name="email" id="email" placeholder="Email Address" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="tel" name="phone-no" id="address" placeholder="Phone Number" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <select style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}>
                                    <option value="">Select Role</option>
                                    <option value="option1">option1</option>
                                  </select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="password" name="Password" id="Password" placeholder="Password" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                              <button type="submit" class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                                Create User
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default AddNewUser;
