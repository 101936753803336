import React, { Component } from "react";
import { Link } from "react-router-dom";

import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify"
import DataTable from "react-data-table-component";
import moment from 'moment';

export class RatesList extends Component {
	componentDidMount = async () => {
		this.getRates();
	};


	getRates = async () => {
		var response = await Api.GetRequest({}, "rates/list");
		if (response.status == 200) {
			this.setState({ rates: response.ratelists });
		} else {
		}
	};


	handleDeleteClick = (rate) => {
		if (window.confirm('do you want to delete ?')) {
			this.confirmDelete(rate)
		}
	}

	confirmDelete = async (rate) => {

		try {
			var response = await Api.PostRequest({ id: rate.id }, "rate/delete")
			if (response.status === 200) {
				const updateRateList = this.state.rates.filter(rate => rate.id !== rate.id)
				this.setState({
					rates: updateRateList
				})
				toast.success("coupon deleted sucessfully")

			} else {
				toast.error("failed to delete")
			}
		} catch (error) {
			toast.error("an error occurred")
		}

	}

	constructor(props) {
		super(props);
		this.state = {
			rates: [],
		};
	}



	columns = [
		{
			name: "S. No.",
			selector: (row, index) => index + 1,
			sortable: true,
		},
		{
			name: "Day Part",
			selector: (row) => (row.day === 0 ? "Day" : "Night"),
			sortable: true,
		},
		{
			name: "Uniform",
			selector: (row) => (row.uniform_type === 0 ? "No" : "Yes"),
			sortable: true,
		},
		{
			name: "Station Type",
			selector: (row) => (row.station_type === 0 ? "In-station" : "Out-station"),
			sortable: true,
		},
		{
			name: "Trip Type",
			selector: (row) => (row.trip_type === 0 ? "One Way" : "Round"),
			sortable: true,
		},
		{
			name: "Duration (in minutes)",
			selector: (row) => row.duration + " mins",
			sortable: true,
		},
		{
			name: "Rate (per minute)",
			selector: (row) => `₹ ${row.rate}`,
			sortable: true,
			right: true,
		},
		{
			name: "",
			cell: (row) => (
				<Link to={`/rates/edit-list/${row.id}`} className="btn btn-info btn-sm mx-2" style={{ whiteSpace: "nowrap" }}>
					<i className="feather icon-edit"></i>&nbsp;Edit
				</Link>
			),
		},
		{
			name: "",
			cell: (row) => (
				<button className="btn btn-danger btn-sm mx-2" onClick={() => this.handleDeleteClick(row)} >
					<i className="feather icon-trash-2"></i>&nbsp;Delete
				</button>
			),
			
		},
	];




	render() {

		

		return (
			<div>
				<div className="pcoded-main-container">
					<div className="pcoded-content">
						<div className="page-header">
							<div className="page-block">
								<div className="row align-items-center">
									<div className="col-md-12">
										<div className="page-header-title">
											<h5 className="m-b-10">Rate List</h5>
										</div>
										<ul className="breadcrumb">
											<li className="breadcrumb-item">
												<a href="index.html">
													<i className="feather icon-home"></i>
												</a>
											</li>
											<li className="breadcrumb-item">
												<a href="#!">Rates</a>
											</li>

											<li className="breadcrumb-item">
												<a href="#!">Rate List</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12">
								<div className="card">
									<div className="card-body">
										<DataTable
											title=""
											fixedHeader
											columns={this.columns}
											data={this.state.rates}
											pagination
											striped
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RatesList;
