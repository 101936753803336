import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


export default class Profile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			'section' : 0
		};
	}

	render() {
		return (
			<>
				{/* <div className="pcoded-main-container">
          <div className="pcoded-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">Profile</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">
                          <i className="feather icon-home"></i>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/profile">Profile</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title">
                  <h5 className="m-b-10" style={{ marginLeft: "20px" }}>
                    Welcome
                  </h5>
                </div>
                <div className="page-header-title" style={{ display: "flex", marginTop: "50px" }}>
                  <div className="input-group-append" style={{ marginLeft: "10px" }}>
                    <button className="button-round-primary" id="add-btn" style={{ border: "none", borderBottom: "2px solid #000" }} onClick={() => this.setState({ isGeneral: true })}>
                      <i className="fas fa-portrait" style={{ paddingRight: "10px", fontSize: "20px" }}></i> General
                    </button>
                  </div>
                  <div className="input-group-append">
                    <button className="button-round-primary" id="add-btn" style={{ marginLeft: "20px", border: "none" }} onClick={() => this.setState({ isGeneral: false })}>
                      <i className="fas fa-key" style={{ paddingRight: "10px", fontSize: "20px" }}></i>Change password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row" style={{ marginTop: "50px", marginLeft: "10px", marginRight: "10px" }}>
            {this.state.isGeneral ? (
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-4">
                  <div className="card" style={{ borderRadius: "15px" }}>
                    <div className="card-body">
                      <h6 style={{ display: "flex", justifyContent: "flex-end" }}>Active</h6>
                      <div className="row mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img className="img-radius" src="/assets/images/user/avatar-2.jpg" alt="User-Profile-Image" />
                      </div>
                      <div className="row mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <h5>Name</h5>
                      </div>
                      <div className="row mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <h5>Email Address</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card" style={{ borderRadius: "15px" }}>
                    <div className="card-body">
                      <div id="collected-chart">
                        <div className="reservation-page-section">
                          <div className="container">
                            <form method="post" className="reservation-form">
                              <div className="reservation-detais form-inner">
                                <div className="row form-wrap">
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                    <input type="text" name="name" id="name" placeholder="Full name" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                    <input type="email" name="email" id="email" placeholder="Email" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                    <input type="tel" name="phone-no" id="address" placeholder="Phone Number" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                                <button type="submit" className="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                                  Save Changes
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    <form method="post" className="reservation-form">
                      <div className="reservation-detais form-inner">
                        <div className="row form-wrap">
                          <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                            <input type="password" name="passworde" id="password" placeholder="Old paasword" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </div>

                          <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                            <input type="password" name="new-password" id="new-password" placeholder="New password" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </div>

                          <div className="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                            <input type="password" name="password" id="password" placeholder="Confirm New Password" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                        <button type="submit" className="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div> */}
				<div className="pcoded-main-container">
					<div className="pcoded-content">
						<div className="page-header">
							<div className="page-block">
								<div className="row align-items-center">
									<div className="col-md-12">
										<div className="page-header-title">
											<h5 className="m-b-10">Coupons</h5>
										</div>
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><a href="index.html"><i className="feather icon-home"></i></a></li>
											<li className="breadcrumb-item"><a href="#!">Membership</a></li>
											<li className="breadcrumb-item"><a href="#!">Coupons</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12">
								<div className="card">
									<div className="card-body">
										<div className="page-header-title">
											<h4 className="m-b-10" style={{ textAlign: 'left' }}>Welcome</h4>
										</div>
										<div className="page-header-title" style={{marginTop: "50px" }}>

											<Tabs id="controlled-tab-example"  defaultActiveKey="0" activeKey={this.state.section} onSelect={(section) => {this.setState({'section' : section})}} className="mb-3">
												<Tab eventKey="0" title="General">
													<form method="post" className="reservation-form mt-4">
														<div className="reservation-detais form-inner">
															<div className="row form-wrap">
																<div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
																	<input type="text" name="name" id="name" placeholder="Full name" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
																</div>

																<div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
																	<input type="email" name="email" id="email" placeholder="Email" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
																</div>

																<div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
																	<input type="tel" name="phone-no" id="address" placeholder="Phone Number" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
																</div>
															</div>
														</div>
														<div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
															<button type="submit" className="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
															Save Changes
															</button>
														</div>
													</form>
												</Tab>
												<Tab eventKey="1" title="Change Password">
													<form method="post" className="reservation-form mt-4">
														<div className="reservation-detais form-inner">
															<div className="row form-wrap">
																<div className="col-12 form-group">
																	<input type="password" name="passworde" id="old-password" placeholder="Old paasword" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
																</div>

																<div className="col-12 form-group">
																	<input type="password" name="new-password" id="new-password" placeholder="New password" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
																</div>

																<div className="col-12 form-group">
																	<input type="password" name="password" id="confirm-password" placeholder="Confirm New Password" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
																</div>
															</div>
														</div>

														<div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
															<button type="submit" className="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>Save Changes</button>
														</div>
													</form>
												</Tab>
											</Tabs>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

}