import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select";

export class CancelReasonsList extends Component {
  reason_list = [
    { value: 2, label: "Customer" },
    { value: 3, label: "Driver" },
  ];
  componentDidMount = async () => {
    this.getCancelReasons();
  };

  getCancelReasons = async () => {
    var response = await Api.GetRequest({}, "cancel-reasons");
    if (response.status == 200) {
      console.log(response);
      this.setState({ cancelReasons: response.cancel_reasons});
    } else {
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      cancelReasons: [],
      'show_reason_model': false,
      current_reason:"",
      id :""


      
    };
  }

  handleEdit =(reason, id) => {
    this.setState({
      current_reason: reason,
      id : id, 
      show_reason_model:true

    })
  }


  handleCloseModel = () => {
		this.setState({ 'show_reason_model': false,
      current_reason: ""
    });
   
	};

  updateCancelReason = async (event) => {
    var data = {
      'id': this.state.id,
      'title': this.state.current_reason,
      
      
    };
    var response = await Api.PostRequest(data, "cancel-reason/edit");
    if (response.status === 200) {
      toast.success(response.message);

      this.handleCloseModel();
      this.getCancelReasons();
    } else {
      toast.error(response.message);
    }
  };



  render() {
    return (
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <Link to="/cancel_reasons/add-cancel-reasons">
            <button type="submit" class="btn btn-success" >
              <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add Cancel Reasons
            </button>
          </Link>
        </div>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Cancel Reasons</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Cancel Reasons</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!"> Cancel Reasons</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="dt-responsive table-responsive">
                      <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                        <thead>
                          <tr>
                            <td colSpan="7" style={{ border: "none" }}>
                              <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                            </td>
                          </tr>
                        </thead>

                        <thead>
                          <tr>
                            <th style={{ border: "none" }}> ID</th>
                            <th style={{ border: "none" }}>Title</th>
                            <th style={{ border: "none" }}>Created At</th>
                            <th style={{ border: "none" }}>update At</th>
                            <th style={{ border: "none" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.cancelReasons.map((cancelreason, index) => {
                              return (
                                <tr>
                                  <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                                  <td style={{ verticalAlign: "middle" }}>{cancelreason.title}</td>
                                  <td style={{ verticalAlign: "middle" }}>{cancelreason.created_at}</td>
                                  <td style={{ verticalAlign: "middle" }}>{cancelreason.updated_at}</td>
                                  <td className="text-right">
                                    <Button onClick={() => this.handleEdit(cancelreason.title, cancelreason.id)} className="btn btn-info btn-sm mx-2">
                                      <i className="feather icon-edit"></i>&nbsp;Edit
                                    </Button>

                                    <button className="btn btn-danger btn-sm mx-2" >
                                      <i className="feather icon-trash-2"></i>&nbsp;Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show_reason_model} onHide={() => this.handleCloseModel()}>
					<Modal.Header>
						<h3>Edit cancel Reason</h3>
					</Modal.Header>
			 		<Modal.Body>
            <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-body">
                      
                        <div class="reservation-detais form-inner">
                          {this.state.current_reason && (
                           
                            <div class="row form-wrap">
                              
                              <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                                  <input type="text" placeholder="Cancel Reason Title" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.current_reason} onChange={(event) => {this.setState({current_reason : event.target.value })}} />
                              </div>
                              <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                                <label> Reason for</label>
                                <Select  value={this.reason_list[this.state.reason_for]} options={this.reason_list} onChange={(event) => { this.setState({ reason_for: event.value }) }} />
                              </div>
                              
                              
                            </div>
                          )}
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                          <button type="submit" onClick={() => { this.updateCancelReason()}} class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                          Update cancel Reason
                          </button>
                        </div>
                      
                    </div>
                  </div>
                </div>
            </div>
           
			 		</Modal.Body>
			 		<Modal.Footer>
					 	<button type="button" class="btn btn-danger">Close</button>
					 	
			 		</Modal.Footer>
			 	</Modal>
      </div>
    );
  }
}

export default CancelReasonsList;
