import React, { Component } from "react";

export class RoleList extends Component {
  render() {
    return (
      <div>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Role List</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-block">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                  <h5 class="m-b-10" style={{ marginLeft: "20px" }}>
                    {" "}
                    Dashboard
                  </h5>
                  <li class="m-b-10" style={{ marginLeft: "20px" }}>
                    <a href="#!">Roles</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style={{ marginLeft: "10px", marginRight: "20px" }}>
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="dt-responsive table-responsive">
                    <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                      <thead>
                        <tr>
                          <td colSpan="7" style={{ border: "none" }}>
                            <input type="search" name="search" id="search" placeholder="Search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </td>
                        </tr>
                      </thead>

                      <thead>
                        <tr>
                          <th> id</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1234</td>
                          <td>2024/04/16</td>
                        </tr>
                        <tr>
                          <td>1234</td>
                          <td>2024/04/25</td>
                        </tr>
                        <tr>
                          <td>1234</td>
                          <td>2011/04/25</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RoleList;
