import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";

export class CouponList extends Component {
  componentDidMount = async () => {
    this.getCoupons();
  };


  getCoupons = async () => {
    var response = await Api.GetRequest({}, "coupons");
    if (response.status == 200) {
      console.log(response);
      this.setState({ coupons: response.coupons });
    } else {
    }
  };

  handleDeleteClick = (coupon) =>{
    if(window.confirm(`do you want to delete  $(coupon.id)`)){
      this.confirmDelete(coupon)
    }
  }

  confirmDelete = async (coupon) => {
    
    try{
      var response =await Api.PostRequest({id:coupon.id} , "coupon/delete")
      if(response.status === 200){
        const updateCoupons = this.state.coupons.filter(coupon => coupon.id !== coupon.id)
        this.setState({
          coupons : updateCoupons
        })
        
          toast.success("coupon deleted sucessfully")
        
      } else {
        toast.error("failed to delete")
      } 
    } catch (error) {
      toast.error("an error occurred")
    }
    
  }
  

  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
    };
  }

  render() {

    return (
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <Link to="/coupons/add-new-coupon">
            <button  type="submit" class="btn btn-success"  >
              <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add Coupon
            </button>
          </Link>
        </div>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Coupon List</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Coupons</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Coupon List</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="dt-responsive table-responsive">
                      <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                        <thead>
                          <tr>
                            <td colSpan="9" style={{ border: "none" }}>
                              <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                            </td>
                          </tr>
                        </thead>

                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>Title</th>
                            <th>code</th>
                            <th>Coupon Type</th>
                            <th>Value</th>
                            <th>Max Value</th>
                            <th>Count</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.coupons.map((coupon, index) => {
                            return (
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>{index + 1}</td>

                                <td style={{ verticalAlign: "middle" }}>{coupon.title}</td>
                                <td style={{ verticalAlign: "middle" }}>{coupon.code}</td>
                                <td style={{ verticalAlign: "middle" }}>{coupon.coupon_type}</td>
                                <td style={{ verticalAlign: "middle" }}>{coupon.value}</td>
                                <td style={{ verticalAlign: "middle" }}>{coupon.max_value}</td>
                                <td style={{ verticalAlign: "middle" }}>{coupon.count}</td>
                                <td style={{ verticalAlign: "middle" }}>{coupon.description}</td>

                                <td className="text-right">
                                  <Link to={`/coupons/edit/${coupon.id}`} className="btn btn-info btn-sm mx-2">
                                    <i className="feather icon-edit"></i>&nbsp;Edit
                                  </Link>

                                  <button className="btn btn-danger btn-sm mx-2" onClick= {() => this.handleDeleteClick(coupon)}>
                                    <i className="feather icon-trash-2"></i>&nbsp;Delete
                                  </button>
                                </td>
                              </tr>

                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CouponList;
