import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify"

export class Taxation extends Component {

  componentDidMount = async () => {
    this.getTaxation();
  };


  getTaxation = async () => {
    var response = await Api.GetRequest({}, "taxes");
    if (response.status == 200) {
      console.log(response);
      this.setState({ taxes: response.taxes});
    } else {
    }
  };

  handleDeleteClick = (tax) =>{
    if(window.confirm(`do you want to delete  $(tax.id)`)){
      this.confirmDelete(tax)
    }
  }

  confirmDelete = async (coupon) => {
    
    try{
      var response =await Api.PostRequest({id:coupon.id} , "tax/delete")
      if(response.status === 200){
        const updateTaxes = this.state.filter(tax => tax.id !== tax.id)
        this.setState({
          taxes : updateTaxes
        })
          toast.success("tax deleted sucessfully")
        
      } else {
        toast.error("failed to delete")
      } 
    } catch (error) {
      toast.error("an error occurred")
    }
    
  }

  constructor(props) {
    super(props);
    this.state = {
      taxes: [],
    };
  }
  render() {
    return (
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <Link to="/rates/AddTaxation">
            <button  type="submit" class="btn btn-success"  >
              <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add Taxation
            </button>
          </Link>
        </div>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Taxation </h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">rates</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!"> Taxation </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="dt-responsive table-responsive">
                      <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                        <thead>
                          <tr>
                            <td colSpan="7" style={{ border: "none" }}>
                              <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                            </td>
                          </tr>
                        </thead>

                        <thead>
                          <tr>
                            <th style={{ border: "none" }}> S.N</th>
                            <th style={{ border: "none" }}>Title</th>
                            <th style={{ border: "none" }} >
                              Tax
                            </th>
                            <th style={{ border: "none" }} >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.taxes.map((tax, index) => {
                            return (
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                                <td style={{ verticalAlign: "middle" }}>{tax.title}</td>
                                <td style={{ verticalAlign: "middle" }}>{tax.tax_percentage}</td>
                                <td className="text-right">
                                  <Link to={`/rates/edit-tax/${tax.id}`} className="btn btn-info btn-sm mx-2">
                                    <i className="feather icon-edit"></i>&nbsp;Edit
                                  </Link>

                                  <button className="btn btn-danger btn-sm mx-2" onClick= {() => this.handleDeleteClick(tax)}>
                                    <i className="feather icon-trash-2"></i>&nbsp;Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}     
                        </tbody>     
                      </table>     
                         
                        
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Taxation </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-block">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                  <h5 class="m-b-10" style={{ marginLeft: "20px" }}>
                    {" "}
                    Dashboard
                  </h5>
                  <li class="m-b-10" style={{ marginLeft: "20px" }}>
                    <a href="#!">Tax Policy</a>
                  </li>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                  <Link to="/rates/AddTaxation">
                    <button onClick={this.additionalchargesClick} type="submit" class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                      <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add Taxation
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style={{ marginLeft: "10px", marginRight: "20px" }}>
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="dt-responsive table-responsive">
                    <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                      <thead>
                        <tr>
                          <td colSpan="7" style={{ border: "none" }}>
                            <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </td>
                        </tr>
                      </thead>

                      <thead>
                        <tr>
                          <th style={{ border: "none" }}> S.N</th>
                          <th style={{ border: "none" }}>Title</th>
                          <th style={{ border: "none" }} colSpan="3">
                            Tax
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1234</td>
                          <td>2024/04/16</td>
                          <td>unpaid</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Taxation;
