import React, { Component } from 'react'
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import withRouter from '../../Services/withRouter'

export class EditRateList extends Component {
  componentDidMount = async()=>{
	if(this.props.params.id){
		this.getRate();
	}
  }

  getRate = async () => {
    var data = {
      id: this.props.params.id,
    };

    var response = await Api.GetRequest(data, 'rate/edit');
    if (response.status == 200) {
      await this.setState({
        "day": response.message.day,
        "duration":response.message.duration,
        "uniform":response.message.uniform,
        "station":response.message.station,
        "trip":response.message.trip,
        "rate":response.message.rate
        
      });
    }
  };

  day_type = [
    { 'value': 0, 'label': 'Day' },
    { 'value': 1, 'label': 'Night' }
  ]

  uniform_type = [
    { 'value': 0, 'label': 'No' },
    { 'value': 1, 'label': 'Yes' }
  ]


  station_type = [
    { 'value': 0, 'label': 'In station' },
    { 'value': 1, 'label': 'Out Station' },
    
  ]
  
  trip_type = [
    { 'value': 0, 'label': 'One way' },
    { 'value': 1, 'label': 'Round' },
    
  ]


  constructor(props) {
    super(props);

    this.state = {
        'day':"",
        'duration':"",
        'uniform':"",
        'station':"",
        'rate':"",
      
    };
  }

  updateRateList = async (event) => {
    var data = {
      'id': this.props.params.id,
      'day': this.state.day,
      'duration': this.state.duration,
      'uniform': this.state.uniform,
      'station': this.state.station,
      'trip': this.state.trip,
      'rate': this.state.rate,
      
      
      
    };
    var response = await Api.PostRequest(data, "rate/edit");
    if (response.status === 200) {
      toast.success(response.message);

      this.props.navigate("/rates/list");
    } else {
      toast.error(response.message);
    }
  };
   
  
  render() {
    return (
      <div>
       
				<div className="pcoded-main-container" style={{ textAlign: "left" }}>
					<div className="pcoded-content">
						<div className="page-header">
							<div className="page-block">
								<div className="row align-items-center">
									<div className="col-md-12">
										<div className="page-header-title">
											<h5 className="m-b-10"> Edit Rate</h5>
										</div>
										
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12">
								<div className="card">
									<div className="card-body">
										<div id="collected-chart">
											<div className="reservation-page-section">
												<div className="container">
														
												</div>
											</div>
										</div>
									</div>
								</div>
								
								<div className="col-md-12">
									<div className="card" style={{ borderRadius: "15px" }}>
										<div className="card-body">
											<div id="collected-chart">
												<div className="reservation-page-section">
													<div className="container">
															<div className="reservation-detais form-inner">
																<div className="row form-wrap">
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Day</label>
                                      <Select value={this.day_type[this.state.day]}  options={this.day_type} onChange={(event) => { this.setState({ 'day': event.value }) }}/>
                                  </div>                                     
																
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		  <label>Duration</label>
                                      <input type="text" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.duration} onChange={(event) => {this.setState({ duration: event.target.value })}} />
                                                                        
																	</div>
																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Uniform</label>
																		<Select value={this.uniform_type[this.state.uniform]}  options={this.uniform_type} onChange={(event) => { this.setState({ 'uniform': event.value }) }} />
																	</div>

																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Station Type</label>
																		<Select value={this.station_type[this.state.station]} options={this.station_type} onChange={(event) => { this.setState({ 'station': event.value }) }} />
																	</div>

																	

																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Trip Type</label>
																		<Select value={this.trip_type[this.state.trip]} options={this.trip_type} onChange={(event) => { this.setState({ 'trip': event.value }) }} />
																	</div>

																	{/* <div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Duration</label>
                                                                        <input type="text" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.duration} onChange={(event) => {this.setState({ duration: event.target.value })}} />
																	</div> */}
																	<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																		<label>Rate</label>
                                    <input type="text" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.rate} onChange={(event) => {this.setState({ rate: event.target.value })}} />
																	</div>
																</div>
															</div>
															<div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center align-items-center d-flex">
																<button type="button" onClick={()=>{this.updateRateList()}} className="btn btn-success">Update Rate List</button>
															</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
      
    )
  }
}

export default withRouter(EditRateList)
