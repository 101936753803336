import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import withRouter from "../../Services/withRouter";

export class EditTax extends Component {
  componentDidMount = async () => {
   if (this.props.params.id) {
     this.getTax();
   }
  }

  getTax = async () => {
    var data = {
      id: this.props.params.id,
    };

    var response = await Api.GetRequest(data, 'tax/edit');
    if (response.status == 200) {
      await this.setState({
        'title': response.tax.title,
        'tax_percentage' : response.tax.tax_percentage,
      });
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      'title': "",
      'tax_percentage':""
    };
  }

  updateTaxes = async (event) => {
    var data = {
      'id': this.props.params.id,
      'title': this.state.title,
      'tax_percentage':this.state.tax_percentage
      
    };
    var response = await Api.PostRequest(data, "tax/edit");
    if (response.status === 200) {
      toast.success(response.message);

      this.props.navigate("/rates/taxation");
    } else {
      toast.error(response.message);
    }
  };

  render() {
    return (
      <div>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Add Tax</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">rates</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!"> Update tax</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Add coupan</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                   
                      <div class="reservation-detais form-inner">
                        <div class="row form-wrap">
                          <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                            <input type="text" placeholder=" Title" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.title} onChange={(event) => {this.setState({ title: event.target.value })}} />
                          </div>

                          <div class="col-lg-12 col-md-6 col-sm-6 col-12 form-group">
                            <input type="text" placeholder="Tax Percentage" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.tax_percentage} onChange={(event) => {this.setState({ tax_percentage: event.target.value })}}/>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                        <button type="button" onClick={() => { this.updateTaxes()}} class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                          Update Taxation
                        </button>
                      </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EditTax)
