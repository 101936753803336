import React, { Component } from "react";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import moment from "moment";
import withRouter from "../../Services/withRouter";
import Autocomplete from "react-google-autocomplete";

export class EditTrip extends Component {

	componentDidMount = async()=>{
		await this.getVehicleTypes();
		await this.getVehicleModels();
		await this.getTransmissionTypes();
		await this.getTrip();
	}


    getTrip = async()=>{
        var id = this.props.params.id || "";
        if (id){
            var response = await Api.GetRequest({'id' : id}, 'trip/edit');
            this.setState({
				"vehicle_type" : {'value' : response.trip.vehicle_type_id, 'label' : response.trip.vehicle_type_name},
				"vehicle_model" : {'value' : response.trip.vehicle_model_id, 'label' : response.trip.vehicle_model_name},
				"transmission_type" : {'value' : response.trip.transmission_type_id, 'label' : response.trip.transmission_type_name},
				"trip_type": response.trip.trip_type,
				"station_type": response.trip.station_type,
				"mobile": response.trip.mobile,
				"pickup_address": response.trip.pickup_address,
				"pickup_latitude": response.trip.pickup_latitude,
				"pickup_longitude": response.trip.pickup_longitude,
				"destination_address": response.trip.destination_address,
				"destination_latitude": response.trip.destination_latitude,
				"destination_longitude": response.trip.destination_longitude,
				"start_date": response.trip.start_date,
				"start_time": moment(response.trip.start_time, 'hh:mm a').format('HH:mm'),
				"end_date": response.trip.end_date,
				"end_time": moment(response.trip.end_time, 'hh:mm a').format('HH:mm'),
				"instructions": response.trip.instructions,
				"id" : response.trip.id
			})
        }
        else{
            this.props.navigate('triptrips/list')
        }
        
    }

	getVehicleTypes  = async() =>{
		var response = await Api.GetRequest({}, 'vehicle-types');
		if(response.status == 200){
			var vehicle_types = [];
			response.vehicle_types.forEach(async (vehicle) => {
				await vehicle_types.push({'value' : vehicle.id, 'label' : vehicle.name});
			});
			this.setState({'vehicle_types' : vehicle_types});

		}
	}


	getVehicleModels  = async() =>{
		var response = await Api.GetRequest({}, 'vehicle-models');
		if(response.status == 200){
			var vehicle_models = [];
			response.vehicle_models.forEach(async (vehicle) => {
				await vehicle_models.push({'value' : vehicle.id, 'label' : vehicle.name});
			});
			this.setState({'vehicle_models' : vehicle_models});
		}
	}


	getTransmissionTypes = async() =>{
		var response = await Api.GetRequest({}, 'transmission-types');
		if(response.status == 200){
			var transmission_types = [];
			response.transmission_types.forEach(async (tranmission) => {
				await transmission_types.push({'value' : tranmission.id, 'label' : tranmission.name});
			});
			this.setState({'transmission_types' : transmission_types});
		}
	}


	trip_types = [
		{ 'value': 0, 'label': 'One way' },
		{ 'value': 1, 'label': 'Round' },
	];


	station_types = [
		{ 'value': 0, 'label': 'In station' },
		{ 'value': 1, 'label': 'Out Station' },
	];


	constructor(props) {
		super(props);
		this.state = {
			"id" : "",
			"vehicle_types" : [],
			"vehicle_models" : [],
			"transmission_types" : [],
			"vehicle_type" : {},
			"vehicle_model" : {},
			"transmission_type" : {},
			"trip_type": "",
			"station_type": "",
			"mobile": "",
			"pickup_address": "",
			"pickup_latitude": 17.4401,
			"pickup_longitude": 78.3489,
			"destination_address": "",
			"destination_latitude": 17.3317,
			"destination_longitude": 78.5754,
			"start_date": "",
			"start_time": "",
			"end_date": "",
			"end_time": "",
			"instructions": ""
		};
	}




	resetState = async()=>{
		this.setState({
			"id" : "",
			"vehicle_type" : {},
			"vehicle_model" : {},
			"transmission_type" : {},
			"trip_type": "",
			"station_type": "",
			"mobile": "",
			"pickup_address": "",
			"pickup_latitude": null,
			"pickup_longitude": null,
			"destination_address": "",
			"destination_latitude": null,
			"destination_longitude": null,
			"start_date": "",
			"start_time": "",
			"end_date": "",
			"end_time": "",
			"instructions": ""
		});
	}




	editTrip = async()=>{
		var data = {
			"id" : this.state.id,
			"vehicle_model_id": this.state.vehicle_model.value,
			"vehicle_model_name": this.state.vehicle_model.label,
			
			"vehicle_type_id": this.state.vehicle_type.value,
			"vehicle_type_name": this.state.vehicle_type.label,

			"transmission_type_id": this.state.transmission_type.value,
			"transmission_type_name": this.state.transmission_type.label,

			"trip_type": this.state.trip_type,
			"mobile": this.state.mobile,
			"pickup_address": this.state.pickup_address,
			"pickup_latitude": this.state.pickup_latitude,
			"pickup_longitude": this.state.pickup_longitude,
			"destination_address": this.state.destination_address,
			"destination_latitude": this.state.destination_latitude,
			"destination_longitude": this.state.destination_longitude,
			'start_date' : moment(this.state.start_date).format('YYYY-MM-DD'),
            'start_time' : moment(this.state.start_time, 'HH:m').format('hh:mm a'),
            'end_date' : moment(this.state.end_date).format('YYYY-MM-DD'),
            'end_time' : moment(this.state.end_time, 'HH:m').format('hh:mm a'),
			"station_type": this.state.station_type,
			"instructions": this.state.instructions
		};
		var response = await Api.PostRequest(data, "trip/edit");
		if (response.status === 200) {
			toast.success("Sucessfully create");
			this.resetState();
			this.props.navigate("/trips/list")
		} 
		else {
			toast.error(response.message);
		}
	};



	render() {
		return (
			<div>
				<div className="pcoded-main-container">
					<div className="pcoded-content" style={{ textAlign: "left" }}>
						<div className="page-header">
							<div className="page-block">
								<div className="row align-items-center">
									<div className="col-md-12">
										<div className="page-header-title">
											<h5 className="m-b-10">Coupons</h5>
										</div>
										<ul className="breadcrumb">
											<li className="breadcrumb-item">
												<a href="index.html">
													<i className="feather icon-home"></i>
												</a>
											</li>
											<li className="breadcrumb-item">
												<a href="#!">Membership</a>
											</li>
											<li className="breadcrumb-item">
												<a href="#!">Coupons</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12">
								<div className="card">
									<div className="card-body">
										<div id="collected-chart">
											<div className="reservation-page-section">
												<div className="container">

													<div className="reservation-detais form-inner">
														<div className="row form-wrap">

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-4 form-group">
																<label>Phone</label>
																<input type="tel" name="phone" id="phone" placeholder="phone No." style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.mobile} onChange={(event) => this.setState({ mobile: event.target.value })} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-4 form-group">
																<label>Vehicle type</label>
																<Select value={this.state.vehicle_type} options={this.state.vehicle_types} onChange={(event) => {this.setState({vehicle_type : event})}} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-4 form-group">
																<label>Vehicle Model</label>
																<Select value={this.state.vehicle_model} options={this.state.vehicle_models} onChange={(event) => {this.setState({vehicle_model : event})}} />
															</div>


															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-4 form-group">
																<label>Tranmission Type</label>
																<Select value={this.state.transmission_type} options={this.state.transmission_types} onChange={(event) => {this.setState({transmission_type : event})}} />
															</div>


															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-4 form-group">
																<label>Trip type</label>
																<Select value={this.trip_types[this.state.trip_type]} options={this.trip_types} onChange={(event) => { this.setState({ trip_type: event.value }) }} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-6 my-4 form-group">
																<label>Station</label>
																<Select value={this.station_types[this.state.station_type]} options={this.station_types} onChange={(event) => { this.setState({ station_type: event.value }) }} />
															</div>


															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																<Autocomplete
																	apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
																	onPlaceSelected={(place) => {
																		this.selectAddress(place, 0);
																	}}
																	options={{
																		types: ["(regions)"],
																		componentRestrictions: { country: "in" },
																	}}
																	defaultValue={this.state.pickup_address}
																	style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}
																/>
															</div>
															
															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																<input type="text" name="destination_address" id="destination_address" placeholder="destination address" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.destination_address} onChange={(event) => this.setState({ destination_address: event.target.value })} />
															</div>
															
															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																<input type="date" name="start_date" placeholder="Start Date" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.start_date} onChange={(event) => this.setState({ start_date: event.target.value })} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																<input type="time" name="start_time" id="start_time" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.start_time} onChange={(event) => this.setState({ start_time: event.target.value })} />
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																<input type="date" name="end_date" placeholder="Trip Date" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.end_date} onChange={(event) => this.setState({ end_date: event.target.value })} />
															</div>
															
															<div className="col-lg-6 col-md-6 col-sm-6 col-12 my-4 form-group">
																<input type="time" name="end_time" id="end_time" placeholder="" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.end_time} onChange={(event) => this.setState({ end_time: event.target.value })} />
															</div>
															
															<div className="col-lg-12 col-md-12 col-sm-12 col-12 my-4 form-group">
																<textarea name="instruction" placeholder=" " style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.instructions} onChange={(event) => this.setState({ instructions: event.target.value })} />
															</div>
														</div>
													</div>


													<center>
														<button type="button" onClick={() => { this.editTrip()}} className="btn btn-primary">Create Trip</button>
													</center>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(EditTrip);
