import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import moment from "moment";

export class VechiclesType extends Component {

  componentDidMount = async () => {
    this.getVehicleType();
  };



  getVehicleType = async () => {
    var response = await Api.GetRequest({}, "vehicle-types");
    if (response.status == 200) {
      console.log(response);
      this.setState({ vehicles: response.vehicle_types });
    } else {
    }
  };


  constructor(props) {
    super(props);

    this.state = {

      showModal: false,
      'name' : "",
      vehicles:[]

    };
  }

  addVechicleType = async () => {
    var data = {
      'name': this.state.name,
      
    };

    var response = await Api.PostRequest(data, "vehicle-types");
    if (response.status === 200) {
      toast.success(response.message);
      this.setState({'name':""});
    } 
    else {
      toast.error(response.message);
    }
  };

  
  handleOpen = () => {
    this.setState({ showModal: true });
  };


  handleClose = () => {
    this.setState({ showModal: false });
  };
  render() {

     const columns =[
			{
				name :"S.NO.",
				selector: (row, index) => index +1,
				sortable :true

			},
			{
				name :"Name",
				selector: row => row.name,
				sortable :true

			},
			
			{
				name :"Created At",
				selector: row => moment(row.created_at).format('111'),
				sortable :true

			},

      {
        name : "Action",
        cell : row =>(
          
          <div className="text-right" style ={{display:"flex"

          }}>
            <Link to={`/vechicles/model-edit/${row.id}`} className="btn btn-info btn-sm mx-2" style={{whiteSpace:"nowrap"}} >
               <i className="feather icon-edit"></i>&nbsp;Edit{" "}
            </Link>
            <button className="btn btn-danger btn-sm mx-2" style={{whiteSpace:"nowrap"}}>
              <i className="feather icon-trash-2"></i>&nbsp;Delete{" "}
            </button>
            

          </div>
        )
      }
		 ]



    return (
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <button  type="submit" class="btn btn-success"  onClick={this.handleOpen}>
            <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add Vechicle Type
          </button>
        </div>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header>
            <h3>Add vehicle Type</h3>
          </Modal.Header>
          <Modal.Body>
            <div class="reservation-page-section">
              <div class="col-md-12">
                <div class="card" style={{ borderRadius: "15px" }}>
                  <div class="card-body">
                    <div class="container">
                      
                        <div class="reservation-detais form-inner">
                          <div class="row form-wrap">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                              <input type="text" name="name" id="name" placeholder="Vehicle Model Name" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} value={this.state.name} onChange={(event) => {  this.setState({ name: event.target.value })}}/>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                          <button type="submit" class="btn btn-success" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }} onClick={() => { this.addVechicleType()}}>
                            Create Vehicle Type
                          </button>
                        </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" class="btn btn-danger" onClick={this.handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Vechicles Types List</h5>
                    </div>
                    <ul class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="index.html">
                          <i class="feather icon-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Vechicles</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#!">Vechicles Types List </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="dt-responsive table-responsive">
                    <DataTable 
                          columns ={columns}
                          data ={this.state.vehicles}
                          pagination
                          selectableRows
                          expandableRows
                          expandableRowsComponent ={({data}) =>(
                          <div>
                            
                            <p><strong>Name :</strong>{data.name}</p>
                            
                            
                           

                          </div>

                          )}
                        
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="pcoded-main-container" style={{ textAlign: "left" }}>
          <div class="pcoded-content">
            <div class="page-header">
              <div class="page-block">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <div class="page-header-title">
                      <h5 class="m-b-10">Vechicles Types List</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-block">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="page-header-title" style={{ listStyle: "inherit", display: "flex" }}>
                  <h5 class="m-b-10" style={{ marginLeft: "20px" }}>
                    
                    Dashboard
                  </h5>
                  <li class="m-b-10" style={{ marginLeft: "20px" }}>
                    <a href="#!">Vechicles Type:List</a>
                  </li>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 form-group" style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                  <Link to="/policy_type/AddPolicyType">
                    <button type="submit" class="button-round-primary" style={{ borderRadius: "10px", borderStyle: "solid", borderColor: "#DDDDDD", color: "rgb(255, 255, 255)", backgroundColor: "rgb(74, 11, 58)", height: "40px" }}>
                      <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Add Vechicles Type
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style={{ marginLeft: "10px", marginRight: "20px" }}>
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="dt-responsive table-responsive">
                    <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                      <thead>
                        <tr>
                          <td colSpan="7" style={{ border: "none" }}>
                            <input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
                          </td>
                        </tr>
                      </thead>

                      <thead>
                        <tr>
                          <th style={{ border: "none" }}>V. ID</th>
                          <th style={{ border: "none" }}>Name</th>

                          <th style={{ border: "none" }}>Created At</th>
                          <th style={{ border: "none" }}>update At</th>
                          <th style={{ border: "none" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1234</td>
                          <td>2024/04/16</td>
                          <td>unpaid</td>
                          <td>unpaid</td>
                          <td>unpaid</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default VechiclesType;
