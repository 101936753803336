import React, { Component } from "react";

export class Default extends Component {
  render() {
    return (

      <>  
      
      <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
			 								<thead>
			 									<tr>
			 										<th>All</th>
			 										<th>Requested Trips</th>
			 										<th>Upcoming Trips</th>
			 										<th>Asigned Trips</th>
			 										<th>Ongoing Trips</th>
			 										<th>Payment Processing</th>
			 										<th>Completed Trips</th>
			 										<th>Cancelled Trips</th>
			 									</tr>
			 								</thead>
			 								<thead>
			 									<tr>
			 										<td colSpan="2" style={{ border: "none" }}>
			 											<select style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}>
			 												<option value="">chose an option</option>
			 												<option value="option1">option1</option>
			 											</select>
			 										</td>

			 										<td colSpan="5" style={{ border: "none" }}>
			 											<input type="search" name="search" id="search" placeholder="search" style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }} />
			 										</td>
			 									</tr>
			 								</thead>

			 								<thead>
			 									<tr>
			 										<th>Otp</th>
			 										<th>Date</th>
			 										<th>Paid</th>
			 										<th>Customer</th>
			 										<th>Driver Asigned</th>
			 										<th>Trip Time</th>
			 										<th>Status</th>
			 									</tr>
			 								</thead>
			 								<tbody>
			 									<tr>
			 										<td>1234</td>
			 										<td>2024/04/16</td>
			 										<td>unpaid</td>
			 										<td>61</td>
			 										<td>john</td>
			 										<td>7:30pm</td>
			 										<td>eztimate created</td>
			 									</tr>
			 									<tr>
			 										<td>1234</td>
			 										<td>2024/04/25</td>
			 										<td></td>
			 										<td>62</td>
			 										<td>johne</td>
			 										<td>7:30am</td>
			 										<td>cancelled</td>
			 									</tr>
			 									<tr>
			 										<td>1234</td>
			 										<td>2011/04/25</td>
			 										<td>unpaid</td>
			 										<td>61</td>
			 										<td>john</td>
			 										<td>7:30pm</td>
			 										<td>eztimate created</td>
			 									</tr>
			 								</tbody>
			 							</table>







                     <div class="pcoded-main-container">
        <div class="pcoded-content">
          <div class="page-header">
            <div class="page-block">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="page-header-title">
                    <h5 class="m-b-10">Coupons</h5>
                  </div>
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
                    <li class="breadcrumb-item"><a href="#!">Membership</a></li>
                    <li class="breadcrumb-item"><a href="#!">Coupons</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  Add section here
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      
    )
  }
}

export default Default;

